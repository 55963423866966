@font-face {
  font-family: "AttackGraffiti";
  src: url(./fonts/AttackGraffiti.woff2);
}

@font-face {
  font-family: "MuseoSlab";
  src: url(./fonts/MuseoSlab.woff);
}

* {
  font-family: MuseoSlab;
}

.custom-toggler {
  display: flex;
}

.colorgold {
  color: goldenrod;
}

.kyc {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.navlogo {}

@media (max-width: 600px) {
  .navlogo {
    max-width: 32%;
    margin-right: 230px;
  }
}

@media (min-width: 604px) {
  .navlogo {
    display: none;
  }
}

@media (min-width: 718px) {
  .navlogo {
    display: none;
  }
}


.toggler {
  color: wheat;
}

@media (max-width: 600px) {
  .toggler {
    margin-top: 26px;
  }
}

/* .mainclass {
  background-color: #000;
} */

/* .navcontainer {
  background-color: #000;
} */

/* .navcontainer .logo {
  padding-top: 15px;
} */




@media (max-width: 767px) {
  .logo {
    display: none;
  }
}

.courselimage {
  width: 100%;
  height: 100%;
  /* padding: 10px; */

}

.image11 {
  border: 1px solid goldenrod;
  border-radius: 10px;

}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .image {
    /* position: absolute;
    left: 25%;
    width: 400px;
    height: 400px;
    margin: -239px 0 0 -79px;
    animation: spin 4s linear infinite; */
  }

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .image {
    /* position: absolute; */
    /* top: 140%; */
    /* left: 25%; */
    /* width: 323px;
    height: 285px;
    margin: -90px 0 0 -24px;
    animation: spin 4s linear infinite; */
  }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .image {
    position: absolute;
    /* top: 140%; */
    left: 25%;
    width: 323px;
    height: 312px;
    margin: -239px 0 0 -79px;
    /* animation: spin 4s linear infinite; */
  }

}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .image {
    position: absolute;
    /* top: 140%; */
    left: 25%;
    width: 400px;
    height: 400px;
    margin: -239px 0 0 -79px;
    /* animation: spin 4s linear infinite; */
  }

}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .image {
    position: absolute;
    /* top: 140%; */
    left: 25%;
    width: 400px;
    height: 400px;
    margin: -239px 0 0 -79px;
    /* animation: spin 4s linear infinite; */
  }

}

.link {
  padding: 20px;
  margin-left: 10px;
  color: Black !important;
  font-size: 20px;

}

.link:hover {
  color: goldenrod !important;
  font-weight: 600;
}

.icon:hover {
  color: goldenrod !important;
  font-size: 50px;

}

.whitepaper {
  font-size: 19px;
  padding: 13px 10px 13px 10px;
  border: 2px solid goldenrod;
  background-color: white;
  border-radius: 7%;
  font-weight: bolder;
}

.whitepaper {
  font-size: 19px;
  padding: 13px 40px 13px 40px;
  border: 2px solid goldenrod;
  background-color: white;
  border-radius: 7%;
  font-weight: bolder;
}

.padiing {
  margin-right: 10px;
}

/* .mintbuttonpill {
  font-size: 19px;
  padding: 13px 10px 13px 10px;
  border: 2px solid goldenrod;
  background-color: white;
  border-radius: 7%;
  font-weight: bolder;
  width: 100px;
} */

/* .mintbuttonpill:hover {
  font-size: 19px;
  padding: 13px 0px 13px 0px;
  border: 2px solid goldenrod;
  background-color: black;
  border-radius: 7%;

} */

.whitepaper:hover {
  font-size: 19px;
  padding: 13px 35px 13px 35px;
  border: 2px solid goldenrod;
  background-color: black;
  border-radius: 7%;

}

.color-white {
  color: goldenrod;
}


.fullheight {
  padding-top: 40%;
  padding-top: 40%;

}

.paddingzayads {
  padding-top: 20rem !important;
}

.bgsection {


  background-image: url("./images/banner.png");
  background-size: contain;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;



}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.white {
  color: white;
}

.SocialIcons {
  padding-top: 10px;
  margin-left: 150px;
}

.SocialIcons {
  padding-top: 10px;
  margin-right: 5px;
}


.parallax {
  /* The image used */
  background-image: url("./images/gamedesign.png");

  /* Set a specific height */
  min-height: 800px;

  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}


/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .bannertext {
    font-size: 80px;
    color: white;
    text-align: center;
    padding-top: 25%;
    font-weight: bolder;
  }

}

/* 
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */
.bannertext {
  font-size: 61px;
  color: white;
  text-align: center;
  padding-top: 25%;
  font-weight: bolder;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .bannertext {
    font-size: 80px;
    color: white;
    text-align: center;
    padding-top: 25%;
    font-weight: bolder;
  }
}

@media (min-width: 768px) {
  .bannertext {
    font-size: 85px;
    color: white;
    text-align: center;
    padding-top: 25%;
    font-weight: bolder;
  }
}

@media (min-width: 992px) {
  .bannertext {
    font-size: 99px;
    color: white;
    text-align: center;
    padding-top: 33%;
    font-weight: bolder;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .bannertext {
    font-size: 136px;
    color: white;
    text-align: center;
    padding-top: 25%;
    font-weight: bolder;
  }
}

/* // XX-Large devices (/ desktops, 1400px and up) */
@media (min-width: 1400px) {
  .bannertext {
    font-size: 136px;
    color: white;
    text-align: center;
    padding-top: 25%;
    font-weight: bolder;
  }
}

@media (max-width: 400.98px) {
  .banner234 {
    height: 550px;
  }
}

.banner234 {
  height: 550px;
}

@media (max-width: 575.98px) {
  .banner234 {
    height: 550px;
  }
}

@media (max-width: 767.98px) {
  .banner234 {
    height: 300px;
  }
}

/* 
.bannertext {
  font-size: 136px;
  color: white;
  text-align: center;
  padding-top: 25%;
  font-weight: bolder;
} */




.col4 {
  /* border: 2px solid red; */
  height: 400px;
}

/* .col1 {
  background-image: url("./images/banana.webp");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.col2 {
  background-image: url("./images/pink.webp");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.col3 {
  background-image: url("./images/white.webp");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

.col44 {
  background-image: url("./images/purple.webp");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
} */

.twocoloumns {
  width: 100%;
  /* height: 455px;
  padding-top: 100px; */
}

.bannertext11:hover {
  animation: animate__zoomOut;
  /* referring directly to the animation's @keyframe declaration */


  /* don't forget to set a duration! */
}

.bannertext112:hover {
  animation: animate__zoomOut;
  /* referring directly to the animation's @keyframe declaration */


  /* don't forget to set a duration! */
}

.smallheadings {
  color: goldenrod;
  font-size: 30px;
  text-align: center;


}


.parat {
  text-align: center;
}

.paragraph1 {
  margin-top: 0px;
  font-size: 25px;
  text-align: center;
  color: white;
}


#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.listtext {
  font-size: 19px;
  color: white;
  padding-top: 10px;

}

.liststyle {
  list-style-type: circle;

}



/* @media (max-width:400px) {
  .bannertext11 {
    display: flex;
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 400;
    font-size: 30px;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: goldenrod !important;
  }


} */

.teambgone {
  width: 100%;
  /* height: 600px; */
  background-image: url("./images/lines.png");
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  background-color: white !important;
}

.teambgcoloums {
  border: 2px solid whitesmoke;
  /* margin: 10px; */
  border-radius: 10px;
}

.teambgcoloums:hover {

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation-name: example;
  animation-duration: 0.25s;
  border-left: 8px solid black;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

}

.teambgcoloums1 {
  border: 2px solid black;
  /* margin: 10px; */
  border-radius: 10px;
}

.teambgcoloums1:hover {

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation-name: example;
  animation-duration: 0.25s;
  border: 8px solid goldenrod;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

}

.colheading {
  justify-content: end;
  /* align-items: center; */
  background-color: white !important;
  display: flex;
  height: fit-content;
  width: 100%;
}

@media (max-width:400px) {

  .colheading {
    justify-content: end;
    /* align-items: center; */
    background-color: white !important;
    display: flex;
    height: fit-content;
    width: 95%;
  }

}




.colheadingtext {
  width: 100%;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  font-weight: 900;
  font-size: 1.5rem;
  font-family: Poppins;
}

@media (max-width:400px) {

  .colheadingtext {
    width: 100%;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    font-weight: 900;
    font-size: 1rem;
    font-family: Poppins;
  }
}

.image1 {
  width: 100%;

}

@media (max-width:400px) {


  .image1 {
    width: 70%;
    justify-content: center;
    align-content: center;
    padding-left: 34%;

  }

}



.cardsrow {
  justify-content: center;
}

.cardtext {
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 11px !important;
}

.cardheading {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
}

.responsive {
  position: relative;
  width: 100%;
  padding-bottom: 40.25%;
  height: 0;
  /* height: 100%; */
  background-attachment: fixed;

}

.responsive video {
  /* border: 5px outset #ddd; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cardtext {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

/* .teambgstart {
  height: 300px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
} */

.teambgContainerheading {
  /* margin: 40px 0px 0px; */
  font-family: Inter, sansTextField-serif;
  line-height: 1.5;
  color: rgb(0, 0, 0);
  font-size: 64px;
  font-weight: 700;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

@media (max-width:400px) {
  .teambgContainerheading {
    /* margin: 40px 0px 0px; */
    font-family: Inter, sansTextField-serif;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    font-size: 30px;
    font-weight: 700;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }



}

.SocialIcons {}

@media (max-width:400px) {
  .SocialIcons {
    display: none;
  }



}

/* .fourcoloumns{
  margin-top: 250px;
} */
.teambgContainer11 {
  height: 50px;
  width: 100%;
  display: block;
}

/* .teambgContainer22 {
  width: 100%;
  height: 900px;
  display: block;
  background-image: url("./images/Black and gold polygons/Seamless patterns/JPG/polygons_pattern3.jpg");
  background-size: 100%;
  background-position: center;
  margin-top: 250px;
  background-attachment: fixed;

} */

.css1qb61jl {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: 0.335rem;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.teambgContainerheading {
  padding-top: 10px;
  padding-bottom: 20px;
}

.css1pz8h27 {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  line-height: 1.5;
  font-size: 72px;
  font-weight: 700;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* .spacer
  {
    width: 100%;
    height: 100px;
    background-color: white;
  } */

.meettheteam {
  /* width: 100%;
  height: 650px; */
  /* background-color: white !important; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  background-color: white !important;
}

.meettheteamheading {
  font-family: Inter, sansTextField-serif;
  color: black;
  font-size: 39px;
  font-weight: 700;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

/* 
.colimage11 {
  height: 250px;
  width: 100%;
  border-radius: 10px;
  background-image: url("./images/three.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.colimage22 {
  height: 250px;
  width: 100%;
  border-radius: 10px;

  background-image: url("./images/one.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.colimage33 {
  height: 250px;
  width: 100%;
  border-radius: 10px;

  background-image: url("./images/two.png");
  background-size: cover;
  background-repeat:roadmapupdatedvideo (5000 × 1500 px).gif no-repeat;
}

.colimage44 {
  height: 250px;
  width: 100%;
  border-radius: 10px;

  background-image: url("./images/zero.png");
  background-size: cover;
  background-repeat: no-repeat;
} */

.cardheading {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  color: black;

}

.cardtext11 {
  margin-right: 22px;
  font-family: Inter, sansTextField-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  color: rgb(250, 248, 248);

}

.cardicon {
  background-color: #000;
  padding: 2px;
  border-radius: 1px;
}

.cardicon:hover {
  background-color: gray;
}

.cardlogo {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

/* # The Rotating Marker # */
details summary::-webkit-details-marker {
  display: none;
}

summary::before {
  font-family: "Hiragino Mincho ProN", "Open Sans", sans-serif;
  content: "▶";
  position: absolute;
  top: 1rem;
  left: 0.8rem;
  transform: rotate(0);
  transform-origin: center;
  transition: 0.2s transform ease;
}

details[open]>summary:before {
  transform: rotate(90deg);
  transition: 0.45s transform ease;
}

/* # The Sliding Summary # */
details {
  overflow: hidden;
}

details summary {
  position: relative;
  z-index: 10;
}

@keyframes details-show {
  from {
    margin-bottom: -80%;
    opacity: 0;
    transform: translateY(-100%);
  }
}

details>*:not(summary) {
  animation: details-show 500ms ease-in-out;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  color: transparent;
  overflow: hidden;
}

details[open]>*:not(summary) {
  color: inherit;
}

/* # Style 2 # */
details.style2 summary::before {
  content: "×";
  color: #FFF;
  font-size: 2rem;
  line-height: 1rem;
  transform: rotate(-45deg);
  top: 1.2rem;
  left: 0.5rem;
}

details[open].style2>summary:before {
  transform: rotate(90deg);
  color: #F00 !important;
  transition: color ease 2s, transform ease 1s;
}

/* # Style 3 # */
details.style3 summary::before {
  content: "›";
  font-size: 2.5rem;
  line-height: 1rem;
  top: 1.3rem;
  left: 0rem;
  margin: -0.5rem -0.5rem 0 0.5rem;
  transform-origin: bottom center;
  transition: margin linear 0.05s;
}

details.style3:hover>summary:before {
  color: #FFF;
}

details[open].style3>summary:before {
  left: 0rem;
  color: #CCC;
  transform: rotate(90deg);
  margin-left: 0.4rem;
  transition: color ease 2s, transform ease 1s, margin ease 1s;
}

@supports (-webkit-touch-callout: none) {
  details.style3 summary::before {
    top: 1.6rem;
  }

  details[open].style3>summary:before {
    top: 1.3rem;
    transition: all 0.8s;
  }
}

/* # Style 4 # */
details.style4 summary {
  padding-right: 2.2rem;
  padding-left: 1rem;
  font-weight: 900;
  color: black;
  font-size: 13px;
}

details.style4 summary::before {
  content: "×";
  color: #FFF;
  font-size: 2rem;
  line-height: 1rem;
  transform: rotate(-45deg);
  top: 1.2rem;
  left: unset;
  right: 0.6rem;
}

details[open].style4>summary:before {
  transform: rotate(90deg);
  color: #F00 !important;
  transition: color ease 2s, transform ease 1s;
}

/* # Style 5 # */
details.style5 summary {
  padding-right: 2.2rem;
  padding-left: 1rem;
}

details.style5 summary::before {
  content: "🙈";
  font-size: 1.5rem;
  top: 0.5rem;
  left: unset;
  right: 0.5rem;
  transform: rotate(0);
}

details.style5:hover>summary::before {
  content: "🙊";
}

details[open].style5>summary::before {
  content: "🐵";
  transform: rotate(0deg);
}

details[open].style5>summary:hover::before {
  content: "🙉";
}

details .monkey-see {
  display: inline;
}

details .monkey-hide {
  display: none;
}

details[open] .monkey-see {
  display: none;
}

details[open] .monkey-hide {
  display: inline;
}

/* # Style 6 # */
details.style6 summary {
  padding-right: 2.2rem;
  padding-left: 1rem;
}

details.style6 summary::before {
  content: "❔";
  font-size: 1.5rem;
  top: 0.5rem;
  left: unset;
  right: 0.5rem;
  transform: rotate(0);
}

details.style6:hover>summary:before {
  content: "👁️‍🗨️";
}

details[open].style6>summary:before {
  content: "❌";
  transform: rotate(0deg);
}

/* # Style 7 # */
details.style7 summary {
  padding-left: 3rem;
}

details[open].style7 summary,
details.style7:hover summary {
  background: #000;
  color: #CCC;
}

details[open].style7 summary strong,
details.style7:hover summary strong {
  color: #FDCE4C;
}

details.style7:hover summary strong {
  color: #ffdf87;
}

details.style7 summary::before {
  content: "🌑";
  font-size: 1.5rem;
  top: 0.5rem;
  left: 0.5rem;
  transform: rotate(0);
}

details.style7:hover>summary::before {
  content: "🌕";
}

details[open].style7>summary::before {
  content: "🌕";
  transform: rotate(0deg);
}

details[open].style7>summary:hover::before {
  content: "🌕";
}

details .moon-new {
  display: inline;
}

details .moon-full {
  display: none;
}

details[open] .moon-new {
  display: none;
}

details[open] .moon-full {
  display: inline;
}

details.style7 .content {
  background: #DDD;
}

/* # Just Some Pretty Styles # */
body {
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
}

p {
  margin: 0;
  padding-bottom: 10px;
}

p:last-child {
  padding: 0;
}

details {
  max-width: 800px;
  box-sizing: border-box;
  margin-top: 5px;
  background: white;
}

summary {
  border: 4px solid transparent;
  outline: none;
  padding: 1rem;
  display: block;
  background: white;
  color: #666;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
}

details[open] summary,
summary:hover {
  color: #FFCA28;
  background: #444;
}

summary:hover strong,
details[open] summary strong,
summary:hover::before,
details[open] summary::before {
  color: #FFA128;
}

.content {
  padding: 10px;
  border: 2px solid #888;
  border-top: none;
  width: 100%;
}


.circle {
  padding: 13px 20px;
  border-radius: 50%;
  background-color: black;
  color: #fff;
  max-height: 50px;
  z-index: 2;
}

.how-it-works.row .col-2 {
  align-self: stretch;
}

.how-it-works.row .col-2::after {
  content: "";
  position: absolute;
  border-left: 3px solid goldenrod;
  z-index: 1;
}

.how-it-works.row .col-2.bottom::after {
  height: 50%;
  left: 50%;
  top: 50%;
}

.how-it-works.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}

.how-it-works.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}


.timeline div {
  padding: 0;
  height: 40px;
}

.timeline hr {
  border-top: 3px solid goldenrod;
  margin: 0;
  top: 17px;
  position: relative;
}

.timeline .col-2 {
  display: flex;
  overflow: hidden;
}

.timeline .corner {
  border: 3px solid goldenrod;
  width: 100%;
  position: relative;
  border-radius: 15px;
}

.timeline .top-right {
  left: 50%;
  top: -50%;
}

.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}

.timeline .top-left {
  left: -50%;
  top: -50%;
}

.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

.bg {
  background-image: url("./images/game 2.0 (5001 × 2000 px).gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.bgroadmap {
  /* background-image: url("./images/roadmapupdated.gif"); */
  background-image: url("./images/roadmapupdatedvideo (5001 × 2000 px) (1).gif");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  /* border: 2px solid goldenrod; */
}




.spaceevenly {
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  justify-content: center;
}

.borderimage {
  border: 1px solid goldenrod;
  border-radius: 10px;
  width: 100%;
  height: 100%;

}

.borderimage1 {
  border: 1px solid goldenrod;
  border-radius: 10px;
  width: 90%;
  height: 90%;

}

.customimageheight {
  height: 175px;
}

.CONSIDER {
  /* background-image: url("./images/roadmapupdated.gif"); */
  background-image: url("./images/replace benefit video (5001 × 2000 px).png");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  /* border: 2px solid goldenrod; */
  PADDING: 0px 0px 0px;
}

.paddingbottom {
  margin: 0px 0px 49px 0px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .customimageheight {
    height: 175px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .paddingbottom {
    margin: 0px 0px 0px 0px;
  }

  .customimageheight {
    height: 63px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .customimageheight {
    height: 175px;

  }

  .CONSIDER {
    /* background-image: url("./images/roadmapupdated.gif"); */
    background-image: url("./images/replace benefit video (5001 × 2000 px).png");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    /* border: 2px solid goldenrod; */
    PADDING: 100px 0px 100px;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .customimageheight {
    height: 175px;
  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .customimageheight {
    height: 171px;
  }
}

@media (min-width: 2500px) {
  .customimageheight {
    height: 220px;
  }
}

.parat {
  margin-top: 0px;
  font-size: 19px;
  text-align: center;
  color: white;
}

.parat12 {
  text-align: center;

}

.flexcenter {
  display: flex;
  align-items: center;
}


.paddingtop {

  padding-top: 125px !important;
  padding-bottom: 10px !important;

}

.banner1Container {
  /* background-image: url("./images/AdobeStock_206005344.jpeg"); */
  background-image: url("./images/AdobeStock_206005344 - Copy (2).jpeg");

  background-size: cover;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.line {
  color: black;
  height: 5px;
}

.banner2Container {
  /* background-image: url("./images/AdobeStock_206005344.jpeg"); */
  /* background-image: url("./images/mintpagedesign.png"); */

  background-size: cover;
  height: 82vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.paragraph {
  margin-top: 10px;
  font-size: 19px;
  text-align: center;
  color: white;
}

.paragraph1234 {
  margin-top: 10px;
  font-size: 19px;
  text-align: center;
  color: white;
}

/* .bannertext112 {
  display: flex;
  margin: 10px;
  font-family: Inter, sansTextField-serif;
  font-weight: 400;
  font-size: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  line-height: 22px;
  color: white !important;
} */
.bannertext112 {
  display: flex;
  /* margin: 10px; */
  font-family: Inter, sansTextField-serif;
  font-weight: 600;
  font-size: 21px;
  justify-content: center;
  text-align: center;
  /* line-height: 37px; */
  color: white !important;
  padding: 0px 10px 0px 10px;
  /* line-height: 35px; */
}

.bannertext11 {
  display: flex;
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-weight: 400;
  font-size: 30px;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  line-height: 52px;
  color: goldenrod !important;
}

.bannertext121 {
  margin: 0px;
  font-family: Inter, sansTextField-serif;
  font-weight: 900;
  font-size: 30px;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: left;
  line-height: 52px;
  color: rgb(255, 255, 255) !important;
  text-align: center;
  padding-top: 10px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

  .paddingtop {

    padding-top: 20px !important;
    padding-bottom: 10px !important;

  }

  .paragraph {
    /* margin-top: 29px; */
    font-size: 13px;
    text-align: center;
    color: white;
  }

  .paragraph1234 {
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
    color: white;
  }

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .bannertext112 {
    display: flex;
    /* margin: 10px; */
    font-family: Inter, sansTextField-serif;
    font-weight: 600;
    font-size: 30px;
    justify-content: center;
    text-align: center;
    /* line-height: 37px; */
    color: white !important;
    padding: 100px 10px 0px 10px;
    line-height: 35px;
  }

  .paragraph {
    /* margin-top: 29px; */
    font-size: 13px;
    text-align: center;
    color: white;
  }

  .paragraph1234 {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .paddingtop {

    padding-top: 20px !important;
    padding-bottom: 10px !important;


  }

  .banner1Container {
    background-image: url("./images/AdobeStock_206005344.jpeg");
    /* background-image: url("./images/AdobeStock_206005344 - Copy.jpeg"); */

    background-size: cover;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .bannertext121 {
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 900;
    font-size: 66px;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    padding-top: 10px;
  }

  .bannertext112 {
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 900;
    font-size: 43px;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: white !important;
    text-align: center;
    padding-top: 10px;
    /* padding: 0px 579px 0px 579px; */
    line-height: 67px;
  }

  /* 
  .bannertext112 {
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 400;
    font-size: 30px;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: white !important;
    text-align: center;
    padding-top: 10px;
    padding: 0px 579px 0px 579px;
    line-height: 67px;
  } */

  /* 
  .bannertext112 {
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 400;
    font-size: 60px;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: white !important;
    text-align: center;
    padding-top: 10px;
    line-height: 67px;
  } */

  .bannertext11 {
    margin: 0px;
    font-family: Inter, sansTextField-serif;
    font-weight: 400;
    font-size: 60px;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: left;
    line-height: 52px;
    color: goldenrod !important;
    text-align: center;
    padding-top: 10px;
  }

  .abvgt {
    margin-top: 0rem !important;
  }

  .paragraph {
    /* margin-top: 29px; */
    font-size: 19px;
    text-align: center;
    color: white;
  }

  .paragraph1234 {
    margin-top: 29px;
    font-size: 22spx;
    text-align: center;
    color: white;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .paddingtop {

    padding-top: 10px !important;
    padding-bottom: 10px !important;

  }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}





/* for the nanbar */
ul li a {
  color: black !important;

  margin: 0px 8px;
  font-family: Inter, sansTextField-serif;
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 500;
}

@media (min-width: 786px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 9px;
  }
}

@media (min-width: 992px) {
  ul li a {
    color: black !important;

    margin: 0px 4px;
    font-family: Inter, sansTextField-serif;
    font-size: 10px;
    line-height: 1.5;
    cursor: pointer;
    font-weight: 500;
  }

  .whitepaper {
    font-size: 11px;
    padding: 13px 10px 13px 10px;
    border: 2px solid goldenrod;
    background-color: white;
    border-radius: 7%;
    font-weight: bolder;

  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    font-size: 12px;
  }
}

@media (min-width: 1100px) {
  ul li a {
    color: black !important;

    margin: 0px 5px;
    font-family: Inter, sansTextField-serif;
    font-size: 12px;
    line-height: 1.5;
    cursor: pointer;
    font-weight: 500;
  }

  .whitepaper {
    font-size: 15px;
    padding: 13px 30px 13px 30px;
    border: 2px solid goldenrod;
    background-color: white;
    border-radius: 7%;
    font-weight: bolder;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 14px;
  }
}



@media (min-width: 1400px) {
  ul li a {
    color: black !important;

    margin: 0px 8px;
    font-family: Inter, sansTextField-serif;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    font-weight: 500;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 20px;
  }
}


.iconfooter {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0px !important;
}

.MINTBUTTOM {
  margin-right: 20px;
}

.mintbutton {}

.classone {
  margin-left: 25px;
  border: 2px solid silver;
  padding: 10px;
  border-radius: 2px;
  text-align: center;
  height: 500px;
  border-radius: 10px;


}

.classonesilevr {
  background-color: Silver;
  background-image: linear-gradient(90deg, Silver, mintcream);
}

.classonegold {
  background-color: #b5c327;
  background-image: linear-gradient(315deg, #b5c327 0%, #ffc907 74%);
}

.classonediamond {
  background-color: #d9e4f5;
  background-image: linear-gradient(315deg, #d9e4f5 0%, #f5e3e6 74%);
}

.packagename {}








/* 
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap
*/
.imaelogo {
  height: 80px;
  margin-left: 17px;
}

.logoheading {
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
  color: #000;
  margin-top: 32px;

}



/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .logoheading {
    font-size: 35px;
    text-align: center;
    font-weight: bolder;
    color: #000;
    margin-top: 68px;
  }

  .imaelogo {
    height: 150px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}